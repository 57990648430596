<template>
  <div class="forgot-email-sent-container">
    <div class="readable_content">
      <h4>Please check your email.</h4>
      <p>
        We've sent a link to reset your password to <strong>{{ $route.params.email }}</strong
        >.
      </p>
      <p>If you don't see the email, check other places it might be, like your junk, spam, social, or other folders.</p>
      <p><router-link to="/">&larr; Back to home</router-link></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'root-forgot-emailsent',
};
</script>

<style scoped lang="scss">
@import './login';
</style>
