<template>
  <div class="get-started-page">
    <mcr-loading-indicator v-if="familyTreePersonLoadingState" :loading="true"></mcr-loading-indicator>
    <div class="readable_content" v-else>
      <h4>Congrats on starting your journey to discover your roots!</h4>
      <div class="supplemental" v-if="title">Your Clan & Surname:</div>
      <div class="surname" v-if="title">
        <h1>{{ title }}</h1>

        <div class="links-container">
          <router-link :to="editSurnameRoute">Edit Surname</router-link>
          <span v-if="surname" class="separator">|</span>
          <router-link v-if="surname" :to="surnamePage">Back to Surname Origins</router-link>
        </div>
      </div>

      <h5>What's next?</h5>

      <next-step-family-tree :family-tree-route="familyTreeRoute" />
      <next-step-ancestors-search :ancestorsSearch="ancestorsSearch" :pinyin="surname" />
      <next-step-guide />
      <next-step-discord></next-step-discord>

      <hr />
      <prospect-request-end-action></prospect-request-end-action>
    </div>
  </div>
</template>

<script>
import {TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';
import {isChineseText} from '@common/utils/utils';
import {getFamilyTreeRoute} from '@common/utils/utils.routes';
import {mapGetters} from 'vuex';

import ProspectRequestEndAction from '@/components/common/prospectRequestEndAction';

import NextStepAncestorsSearch from '@/components/modules/getStarted/NextStepAncestorsSearch';
import NextStepDiscord from '@/components/modules/getStarted/NextStepDiscord';
import NextStepFamilyTree from '@/components/modules/getStarted/NextStepFamilyTree';
import NextStepGuide from '@/components/modules/getStarted/NextStepGuide';

export default {
  created() {
    this.$store.dispatch('fetchFamilyTreePersonAction', 'my');
  },
  computed: {
    ...mapGetters(['familyTreePersonState', 'familyTreePersonLoadingState']),
    title() {
      return this.pinyin || this.chineseSurname ? `${this.pinyin || ''} ${this.chineseSurname || ''}` : '';
    },
    chineseSurname() {
      const surnames = this.familyTreePersonState.surnames || [];
      const surname = surnames.find(surname => isChineseText(surname.value));
      return surname ? surname.value : '';
    },
    pinyin() {
      const surnames = this.familyTreePersonState.surnames || [];
      const surname = surnames.find(surname => !isChineseText(surname.value));
      return surname ? surname.value : '';
    },
    surname() {
      return this.chineseSurname || this.pinyin;
    },
    familyTreeRoute() {
      return getFamilyTreeRoute(this.$store);
    },
    editSurnameRoute() {
      const params = {id: this.familyTreePersonState.family_tree_id, personId: this.familyTreePersonState.object_id};
      const query = {tab: 'details', edit: 'true'};
      return {name: 'familytree-profile-details', params, query};
    },
    surnamePage() {
      return {name: 'search-tool-surname-detail', query: {word: this.surname}};
    },
    ancestorsSearch() {
      const query = {surname: this.surname || '', tab: TAB_ID_INDEXED_RECORDS};
      return {name: 'search-all-records', query};
    },
  },
  components: {ProspectRequestEndAction, NextStepDiscord, NextStepGuide, NextStepAncestorsSearch, NextStepFamilyTree},
};
</script>

<style lang="scss" scoped>
.surname {
  padding-bottom: 30px;
  border-bottom: 1px solid $divider-line-color;
  .links-container {
    display: flex;
    .separator {
      margin: 0 10px;
    }
  }
  @media only screen and (max-width: $breakpoint-mobile) {
    .links-container {
      flex-direction: column;
      .separator {
        display: none;
      }
      a:first-child {
        margin: 10px 0;
      }
    }
  }
}
.next-step {
  margin-top: 50px;
}

hr {
  margin: 70px 0 40px;
}
</style>
