<template>
  <div class="verify-account-page">
    <div class="readable_content">
      <h4 class="title">{{ title }}</h4>
      <div class="description">
        <div>We've sent an email to {{ userEmailState }} to verify and activate your account.</div>
        <div>The link in the email will expire after 24 hours.</div>
      </div>
      <div class="helper">Didn't get it? Check your spam, or <a @click="resendVerifyEmail">Resend Verification</a></div>

      <mcr-button @click="onVerifyClick" :loading="userLoading">I've verified my email</mcr-button>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import {mapGetters} from 'vuex';

export default {
  components: {McrButton},
  data() {
    return {
      userLoading: false,
      resent: !!this.$route.query.resent,
    };
  },
  computed: {
    ...mapGetters(['userEmailState']),
    title() {
      if (this.resent) {
        return 'Verification email resent';
      }
      return 'Please verify your account';
    },
  },
  methods: {
    resendVerifyEmail() {
      this.network.member
        .resendVerifyEmail()
        .then(() => {
          this.resent = true;
          this.$toasted.success('Verification email resent');
        })
        .catch(error => {
          const message = error && error.response && error.response.data && error.response.data.error;
          this.$toasted.error(message || 'Error');
        });
    },
    onVerifyClick() {
      const redirectRoute = this.$route.query.redirect || {name: 'main-page'};
      if (this.$store.getters.userEmailVerifiedState) {
        this.$router.replace(redirectRoute);
        return;
      }
      this.checkUserVerified(redirectRoute);
    },
    checkUserVerified(redirectRoute) {
      this.userLoading = true;
      this.$store
        .dispatch('refreshMyInformationAction')
        .then(response => {
          if (response.email_verified) {
            this.$router.replace(redirectRoute);
          } else {
            this.$toasted.error('Account is not verified, please try resending verification.');
          }
        })
        .finally(() => {
          this.userLoading = false;
        });
    },
  },
  name: 'RequireVerifyAccountPage',
};
</script>

<style lang="scss" scoped>
.verify-account-page {
  margin-top: 40px;

  .readable_content {
    max-width: 500px;
  }

  .description {
    margin: 20px 0 30px;
  }

  .mcr-button {
    margin-top: 30px;
  }

  h4 {
    border-bottom: 0;
    padding-bottom: 0;
  }
}
</style>
