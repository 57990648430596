<template>
  <div class="register-container" :class="{'has-splash': showSplash}">
    <splash-image-light v-if="showSplash"></splash-image-light>

    <div class="readable_content account-form-container">
      <h3 class="title">{{ title }}</h3>
      <div class="text-lg subtitle">{{ subtitle }}</div>
      <div class="text-lg subtitle" v-if="referralSubTitle">{{ referralSubTitle }}</div>
      <div class="initial-loading" v-if="initialLoading">
        <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
      </div>
      <div class="account-form" v-else>
        <div class="social-login-buttons">
          <facebook-sign-in-button-container
            class="social-button"
            :referral-code="$route.query.referral"
            @success="onSuccess"
            @fail="onFailure"
          ></facebook-sign-in-button-container>
          <google-sign-in-button-container
            class="social-button"
            :referral-code="$route.query.referral"
            @success="onSuccess"
            @fail="onFailure"
          ></google-sign-in-button-container>
        </div>
        <div class="social-divider">
          <span>OR</span>
        </div>

        <input
          placeholder="First Name"
          v-model="firstname"
          type="text"
          :ref="firstInputRef"
          :class="{'has-error': firstNameError}"
          class="input"
          @keyup.enter="_confirm()"
        />
        <div class="input-error" v-if="firstNameError">{{ firstNameError }}</div>
        <input
          placeholder="Last Name"
          v-model="surname"
          type="text"
          @keyup.enter="_confirm()"
          class="input"
          :class="{'has-error': lastNameError}"
        />
        <div class="input-error" v-if="lastNameError">{{ lastNameError }}</div>
        <email-input :email="email" @input="onEmailChange" :ref="emailInputRef" @enter="_confirm()"></email-input>
        <password-input
          :password="password"
          :error="passwordErrorActive"
          @input="onPasswordChange"
          @enter="_confirm()"
        ></password-input>
        <div class="input-error" :class="{inactive: !passwordErrorActive}">{{ passwordError }}</div>

        <p class="error" v-if="errorMessage">
          <transition name="fade" v-for="message in errorMessage" :key="message">
            <span>{{ message }}<br /></span>
          </transition>
        </p>

        <div class="subscription_prompt">
          <input type="checkbox" id="subscribe" v-model="subscribeToNewsletter" />
          <label for="subscribe">Email me about news, tips, and events</label>
        </div>

        <mcr-button @click="_confirm" :loading="submitting">
          <span>Register for Free</span>
        </mcr-button>

        <div class="terms_privacy">
          <p>
            By registering, you agree to our <router-link :to="{name: 'terms'}">terms</router-link> and
            <router-link :to="{name: 'privacy-policy'}">privacy policy</router-link>.
          </p>
        </div>
      </div>
      <div class="action_links">
        <router-link :to="{name: 'login', query: loginPageQuery}"> Log in with an existing account </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import mcrButton from '@common/elements/buttons/mcrButton';
import EmailInput from '@common/elements/inputs/EmailInput.vue';
import PasswordInput from '@common/elements/inputs/passwordInput.vue';
import AnalyticsHubspotHandler from '@common/utils/analytics/analytics.hubspot';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {isEmailValid} from '@common/utils/utils';
import {getRouteNameByUrl} from '@common/utils/utils.routes';
import {mapGetters} from 'vuex';

import FacebookSignInButtonContainer from '@/base/elements/social-buttons/FacebookSignInButtonContainer';
import GoogleSignInButtonContainer from '@/base/elements/social-buttons/GoogleSignInButtonContainer';

import SplashImageLight from '@/components/modules/login/SplashImageLight';

export default {
  components: {
    EmailInput,
    SplashImageLight,
    PasswordInput,
    mcrButton,
    GoogleSignInButtonContainer,
    FacebookSignInButtonContainer,
  },
  name: 'root-register',
  data() {
    return {
      firstname: '',
      surname: '',
      email: '',
      password: '',
      errorMessage: null,

      firstNameError: '',
      lastNameError: '',
      emailError: '',
      passwordError: 'Password needs to be at least 8 characters long.',
      passwordErrorActive: false,

      submitting: false,
      subscribeToNewsletter: false,
      emailInputRef: 'email-input',
      firstInputRef: 'firstname-input',
      initialLoading: this.$route.query.referral,
    };
  },
  mounted() {
    if (this.$route.query.referral) {
      this.$store.dispatch('getReferralCodeAction', this.$route.query.referral).finally(() => {
        this.initialLoading = false;
        this.$nextTick(() => {
          this.init();
        });
      });
    } else {
      this.init();
    }
  },
  destroyed() {
    this.$store.commit('setRegisterReferralDataState', null);
  },
  computed: {
    ...mapGetters(['registerReferralDataState']),
    redirectPath() {
      return this.$route.query.redirect;
    },
    loginPageQuery() {
      return {redirect: this.redirectPath};
    },
    title() {
      return 'Sign Up to Continue';
    },
    subtitle() {
      return 'Create a free My China Roots account';
    },
    showSplash() {
      return this.$store.getters.windowWidthState > this.$breakpoints.desktop;
    },
    referralSubTitle() {
      const data = this.registerReferralDataState;
      return data && data.name ? `Referral from ${data.name}` : '';
    },
  },
  methods: {
    init() {
      this.$store.dispatch('autofocusAction', this.$refs[this.firstInputRef]);
      this.email = this.$route.query.email ? this.$route.query.email : this.email;
      this.email = this.email || sessionStorage.getItem('contactEmail') || '';
      if (this.$store.getters.surnamesRegisterState) {
        this.surname = this.$store.getters.surnamesRegisterState[0];
      }
    },
    onEmailChange(event) {
      this.email = event.target.value;
    },
    onPasswordChange(event) {
      this.password = event.target.value;
    },
    _confirm() {
      AnalyticsMainHandler.trackClickRegisterButton();
      this.errorMessage = null;
      this.passwordErrorActive = false;
      this.emailError = this.$refs[this.firstInputRef].error;
      this.firstNameError = '';
      this.lastNameError = '';

      if (!this.email || this.email.length <= 0 || !isEmailValid(this.email)) {
        this.emailError = 'Please enter your email in a supported format.';
      }

      if (!this.password || this.password.length <= 0 || this.password.length < 8) {
        this.passwordErrorActive = true;
      }
      if (!this.firstname) {
        this.firstNameError = 'This field is required';
      }
      if (!this.surname) {
        this.lastNameError = 'This field is required';
      }

      if (this.submitting || this.emailError || this.passwordErrorActive || this.firstNameError || this.lastNameError) {
        return;
      }

      this.submitting = true;

      this.network.member
        .register({
          email: this.email,
          first_name: this.firstname,
          surname: this.surname,
          password: this.password,
          password_confirm: this.password,
          subscribe_to_newsletter: this.subscribeToNewsletter,
          entry_point_url: this.$store.getters.fullPathBeforeLoginRegisterState,
          entry_point_name: getRouteNameByUrl(this.$store.getters.fullPathBeforeLoginRegisterState, this.$router),
          referral_code: this.$route.query.referral,
          surnames: this.$store.getters.surnamesRegisterState,
        })
        .then(res => {
          this.errorMessage = null;
          if (!res) {
            this.errorMessage = ['Requesting Error'];
            this.submitting = false;
            return;
          }
          if (res._detail && res._detail.length > 0) {
            this.errorMessage = res._detail;
            this.submitting = false;
            return;
          }
          this.emptyRegisterSurnames();
          this.login();
        })
        .catch(() => {
          this.submitting = false;
        });
    },

    login() {
      this.$store
        .dispatch('userLoginAction', {
          email: this.email,
          password: this.password,
        })
        .then(res => {
          this.getUserDefinedData(res);
        })
        .catch(() => {
          this.submitting = false;
        });
    },

    getUserDefinedData(res) {
      this.$store.dispatch('fetchMyInformationAction').then(res => {
        AnalyticsMainHandler.setUserId(res.email);
        AnalyticsHubspotHandler.identify(res.email);
        const threeDays = 1000 * 60 * 60 * 24 * 3;
        this.$store.commit('setNextVerifyNotificationTimeState', new Date().getTime() + threeDays);
        this.$nextTick(() => {
          this.$router.push(this.getNextRoute());
        });
      });
    },
    onSuccess(res) {
      this.emptyRegisterSurnames();
      this.getUserDefinedData(res);
    },
    onFailure(error) {
      this.errorMessage = error.response.data;
      this.submitting = false;
    },
    emptyRegisterSurnames() {
      this.$store.commit('setSurnamesRegisterState', []);
    },
    getNextRoute() {
      if (
        this.$route.query.referral &&
        this.registerReferralDataState &&
        this.registerReferralDataState.redirect_path
      ) {
        return {path: this.registerReferralDataState.redirect_path};
      }
      let allowPaths = ['/subscription-plans', '/familytree', '/person-records'];
      allowPaths = [...allowPaths, ...allowPaths.map(text => text.replace('/', '%2F'))];
      if (this.redirectPath && allowPaths.some(path => this.redirectPath.startsWith(path))) {
        return {path: this.redirectPath};
      }
      if (this.$store.getters.featureSwitchesState.ONBOARDING) {
        return {name: 'familytree-onboarding-start'};
      }
      // todo: remove this line when remove ONBOARDING and always redirect to onboarding here.
      return this.redirectPath ? {path: this.redirectPath} : {name: 'search-all-records'};
    },
  },
};
</script>

<style scoped lang="scss">
@import './login';
.title {
  white-space: break-spaces;
  text-align: center;
  margin-bottom: 4px;
}
.subtitle {
  color: $neutral-500;
}
</style>
