<template>
  <div class="user-profile-container">
    <div class="readable_content">
      <h4>Account</h4>

      <mcr-loading-indicator :loading="loading"></mcr-loading-indicator>

      <div class="content" v-if="!loading && user">
        <div class="acccount setting-column">
          <div class="setting-group">
            <div class="bold">Membership</div>
            <current-subscriptions
              :loading="subscriptionsLoadingState || subscriptionPlansLoadingState"
              :subscriptions="subscriptionsState"
              :plans="subscriptionPlansState"
            ></current-subscriptions>
          </div>
          <mcr-button
            v-if="user.has_customer_id"
            class="edit-billing-info-button"
            :loading="editBillingInfoLoading"
            @click="editBillingInfo"
            >Edit Billing Info</mcr-button
          >

          <div class="setting-group">
            <div class="bold">Account</div>
            <profile-edit-item :label="'Email'" :placeholder="'Email'" :content="user.email" :hidden-edit="true">
              <verify-email v-if="!userEmailVerifiedState"></verify-email>
            </profile-edit-item>

            <profile-edit-password :user="user" @savedOnItem="saveOnItem"></profile-edit-password>
          </div>

          <div class="setting-group">
            <div class="bold">Profile</div>
            <profile-edit-item
              :label="'First Name'"
              :placeholder="'First Name'"
              :content="user.first_name"
              :requestkey="'first_name'"
              :user="user"
              @savedOnItem="saveOnItem"
            ></profile-edit-item>
            <profile-edit-item
              :label="'Last Name'"
              :placeholder="'Last Name'"
              :content="user.last_name"
              :requestkey="'last_name'"
              :user="user"
              @savedOnItem="saveOnItem"
            ></profile-edit-item>
          </div>

          <div class="email-settings setting-group">
            <div class="bold">Email Notification Settings</div>
            <emailing-settings
              v-if="showEmailSettings"
              class="profile-edit-item"
              :settings="emailSettings"
              :email="user.email"
              :loading="emailSettingsUpdateLoading"
              @submit="triggerSubScrip"
            ></emailing-settings>
            <mcr-loading-indicator v-else-if="emailSettingsLoading" :loading="true"></mcr-loading-indicator>
            <mcr-button v-else class="manage-email-settings" @click="loadEmailSettings">Manage Preferences</mcr-button>
          </div>

          <div class="setting-group">
            <div class="bold">Social Accounts</div>
            <div class="profile-edit-item">
              <google-connect-button-container
                class="social-auth-button"
                @success="_loadData"
              ></google-connect-button-container>
              <span class="social-auth-error">{{ googleConnectErrorState }}</span>
            </div>

            <div class="profile-edit-item">
              <facebook-connect-button-container
                class="social-auth-button"
                @success="_loadData"
              ></facebook-connect-button-container>
              <span class="social-auth-error">{{ facebookConnectErrorState }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import {mapGetters} from 'vuex';

import FacebookConnectButtonContainer from '@/base/elements/social-buttons/FacebookConnectButtonContainer';
import GoogleConnectButtonContainer from '@/base/elements/social-buttons/GoogleConnectButtonContainer';

import CurrentSubscriptions from '@/components/modules/user/CurrentSubscriptions';

import VerifyEmail from './VerifyEmail';
import EmailingSettings from './email-settings/EmailingSettings';
import ProfileEditItem from './profile.edit.item';
import ProfileEditPassword from './profile.edit.password';

export default {
  components: {
    CurrentSubscriptions,
    McrButton,
    FacebookConnectButtonContainer,
    GoogleConnectButtonContainer,
    ProfileEditPassword,
    ProfileEditItem,
    EmailingSettings,
    VerifyEmail,
  },
  created() {
    this.$store.commit('setGoogleConnectErrorState', null);
    this.$store.commit('setFacebookConnectErrorState', null);
    this.$store.dispatch('fetchSubscriptionsAction');
    if (!this.subscriptionPlansState.length) {
      this.$store.dispatch('fetchSubscriptionPlansAction');
    }
  },
  name: 'profile',
  data() {
    return {
      user: {},
      loading: false,
      editBillingInfoLoading: false,

      emailSettings: [],
      emailSettingsLoading: false,
      emailSettingsUpdateLoading: false,
    };
  },
  mounted() {
    this._loadData();
    this.$store.dispatch('fetchFamilyTreeListAction');
  },
  computed: {
    ...mapGetters([
      'facebookConnectErrorState',
      'googleConnectErrorState',
      'userEmailVerifiedState',
      'subscriptionsState',
      'subscriptionPlansState',
      'subscriptionsLoadingState',
      'subscriptionPlansLoadingState',
    ]),
    showEmailSettings() {
      return this.emailSettings.length > 0;
    },
  },
  methods: {
    _loadData() {
      this.user = undefined;
      this.loading = true;

      this.$store
        .dispatch('fetchMyInformationAction')
        .then(res => {
          this.user = res;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    saveOnItem() {
      this.user = undefined;
      this._loadData();
      this.$toasted.success('Updated Successfully!');
    },

    triggerSubScrip(subscriptionElement) {
      const checkboxes = subscriptionElement.getCheckboxesState();
      const settings = checkboxes.map(item => ({list_public_id: item.publicListId, value: item.checked}));
      const changedSettings = settings.filter(obj => {
        return obj.value !== this.emailSettings.find(obj2 => obj2.list_public_id === obj.list_public_id).value;
      });
      const didSubscribe = changedSettings.filter(obj => !!obj.value).length > 0;

      let params = {settings: changedSettings};
      this.emailSettingsUpdateLoading = true;
      this.network.member
        .changeEmailSettings(params)
        .then(res => {
          if (didSubscribe) {
            subscriptionElement.$refs.form.submit();
            return;
          }
          this.$toasted.success('Updated Successfully!');
        })
        .catch(err => {
          console.error(err);
          this.$toasted.error('Update failed. Please try again later.');
        })
        .finally(() => {
          this.emailSettingsUpdateLoading = false;
        });
    },
    editBillingInfo() {
      this.editBillingInfoLoading = true;
      this.network.member
        .customerPortalUrl()
        .then(response => {
          if (response.url) {
            window.location.href = response.url;
          }
        })
        .catch(() => {
          this.editBillingInfoLoading = false;
        });
    },
    loadEmailSettings() {
      this.emailSettingsLoading = true;
      this.network.member
        .getEmailSettings()
        .then(res => {
          this.emailSettings = res;
        })
        .finally(() => {
          this.emailSettingsLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import './profile';

.ee-subscription-form::v-deep .headers {
  display: none;
}

.user-profile-container {
  .content {
    .setting-column {
      .setting-group {
        margin-bottom: 35px;
        &:last-child {
          margin-bottom: 0px;
        }
      }

      .edit-billing-info-button {
        max-width: 300px;
        margin-bottom: 30px;
      }

      .email-settings {
        .emailing-settings.profile-edit-item {
          flex-direction: column;
          align-items: flex-start;
        }

        .manage-email-settings.mcr-button {
          max-width: 300px;
          margin-top: 20px;
        }
      }
    }
  }
}
.social-auth-button {
  flex-grow: 1;
  max-width: 500px;
}
.social-auth-error {
  color: $error-color;
  padding-left: 20px;
}
</style>
