<template>
  <google-sign-in-button
    id="google-signin-button"
    :on-sign-in="onSignIn"
    :on-failure="onFailure"
    :disabled="isConnected"
  >
    <span v-if="isConnected">Google account connected</span>
    <span v-else>Connect Google Account</span>
  </google-sign-in-button>
</template>

<script>
import {mapGetters} from 'vuex';

import GoogleSignInButton from './GoogleSignInButton';

export default {
  components: {GoogleSignInButton},
  methods: {
    onSignIn(user) {
      this.$store.dispatch('googleConnectAction', user.access_token).then(response => {
        this.$emit('success', response);
      });
    },
    onFailure(response) {
      console.log('google connect fail: ', response);
    },
  },
  computed: {
    ...mapGetters(['socialAccountsState']),
    isConnected() {
      return Boolean(
        this.socialAccountsState && this.socialAccountsState.filter(a => a.provider.includes('google')).length
      );
    },
  },
};
</script>

<style scoped></style>
