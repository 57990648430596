<template>
  <div class="splash-image-light">
    <div class="splash-wrapper">
      <div class="main-title">
        <h1>Explore your heritage with My China Roots</h1>
        <div class="subtitle heading-6">Build your family tree, safely and securely.</div>
        <div class="subtitle heading-6">Discover records of your ancestors in China and beyond!</div>
      </div>
      <img :src="$getAsset('/assets/services/world-map-register.png')" width="839" height="530" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SplashImageLight',
};
</script>

<style lang="scss" scoped>
.splash-image-light {
  background-color: #f3eee8;
  flex-basis: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .splash-wrapper {
    max-width: 840px;
  }

  .main-title {
    margin-left: 80px;
  }

  h1 {
    margin-bottom: 12px;
    width: 420px;
  }
  .subtitle.heading-6 {
    color: $neutral-500;
    width: 520px;
    font-weight: normal;
  }

  img {
    object-fit: cover;
  }
}
</style>
