<template>
  <div class="subscription-reactivated-modal with-sticky-buttons">
    <div class="heading">
      <div class="text-lg bold">Glad to have you back!</div>
      <close-icon class="close-icon" @click="cancelClick"></close-icon>
    </div>
    <div class="content">
      <div>Your {{ subscription.plan.display_text }} membership has been reactivated.</div>
      <div>You will be billed ${{ subscription.plan.cost }} on {{ expireDate }}.</div>
    </div>
    <div class="action-buttons">
      <mcr-button class="confirm-button" @click="okClick">Got it</mcr-button>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton.vue';
import {getFamilyTreeRoute} from '@common/utils/utils.routes';
import moment from 'moment/moment';
import CloseIcon from 'vue-material-design-icons/Close';

export default {
  props: {
    subscription: Object,
  },
  computed: {
    expireDate() {
      return moment(decodeURIComponent(this.subscription.expire_on)).format('MMMM DD, YYYY');
    },
  },
  methods: {
    cancelClick() {
      this.$emit('close');
    },
    okClick() {
      this.$emit('close');
      this.$router.push(getFamilyTreeRoute(this.$store));
    },
  },
  components: {McrButton, CloseIcon},
  name: 'SubscriptionReactivatedModal',
};
</script>

<style scoped lang="scss">
.subscription-reactivated-modal {
  .content {
    div + div {
      margin-top: 8px;
    }
  }
  .action-buttons {
    .mcr-button {
      flex-grow: 1;
    }
  }
}
</style>
