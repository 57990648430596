<template>
  <div class="subscription-reactivate-button" @click="reactivateSubscription">
    <slot name="element" :loading="loading">
      <bounce-loading v-if="loading" class="dark"></bounce-loading>
      <a v-else>Reactivate Membership</a>
    </slot>
  </div>
</template>

<script>
import BounceLoading from '@common/elements/loading-indicators/bounceLoading';

import SubscriptionReactivatedModal from '@/components/common/SubscriptionReactivatedModal';

export default {
  props: {
    subscription: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    subscriptionId() {
      return this.subscription.id;
    },
  },
  methods: {
    reactivateSubscription() {
      this.loading = true;
      this.network.subscription
        .reactivateSubscription({id: this.subscriptionId})
        .then(() => {
          const newData = {cancel_at_period_end: false};
          this.$store.commit('mutateSubscriptionsState', {subId: this.subscriptionId, newData});
          this.loading = false;
          let modalParams = {
            classes: 'clear_modal white_modal',
            class: 'mobile_bottom',
            name: 'subscription-reactivated-modal',
            height: 'auto',
            scrollable: true,
          };
          this.$modal.show(SubscriptionReactivatedModal, {subscription: this.subscription}, modalParams);
        })
        .catch(() => {
          this.$toasted.error('Something went wrong, please try again later.');
        });
    },
  },
  components: {BounceLoading},
  name: 'SubscriptionReactivateButton',
};
</script>

<style scoped lang="scss"></style>
