<template>
  <div class="verify-email">
    <div class="supplemental flex-row"><alert-icon /> Not verified</div>
    <a @click="resendVerifyEmail">Resend verification email</a>
  </div>
</template>

<script>
import AlertIcon from 'vue-material-design-icons/Alert';

export default {
  methods: {
    resendVerifyEmail() {
      this.network.member
        .resendVerifyEmail()
        .then(() => {
          this.$router.push({name: 'require-verify', query: {resent: 'true', redirect: this.$route.fullPath}});
        })
        .catch(error => {
          const message = error && error.response && error.response.data && error.response.data.error;
          this.$toasted.error(message || 'Error');
        });
    },
  },
  components: {AlertIcon},
  name: 'VerifyEmail',
};
</script>

<style lang="scss" scoped>
.verify-email {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
