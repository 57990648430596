<template>
  <div class="google-sign-in-button" :class="{disabled: disabled}" @click="prompt">
    <div class="abcRioButton abcRioButtonBlue">
      <div class="abcRioButtonContentWrapper">
        <div class="abcRioButtonIcon">
          <div class="abcRioButtonSvgImageWithFallback abcRioButtonIconImage abcRioButtonIconImage18">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="18px"
              height="18px"
              viewBox="0 0 48 48"
              class="abcRioButtonSvg"
            >
              <g>
                <path
                  fill="#EA4335"
                  d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                ></path>
                <path
                  fill="#4285F4"
                  d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                ></path>
                <path
                  fill="#FBBC05"
                  d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                ></path>
                <path
                  fill="#34A853"
                  d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                ></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </g>
            </svg>
          </div>
        </div>
        <span class="abcRioButtonContents">
          <slot></slot>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
// the button styles and html are copied from official button theme
// can not reuse the `signin2.render()` that renders such button due to it makes google request right after button is
// rendered, while it's only needed after user will click the button
// the Branding Guidelines: https://developers.google.com/identity/branding-guidelines
// the issue: https://stackoverflow.com/questions/32624007/initiate-google-sign-in-on-button-click

export default {
  props: {
    onSignIn: Function,
    onFailure: Function,
    disabled: Boolean,
  },
  created() {
    this.initGoogle();
  },
  data() {
    return {
      tokenClient: null,
    };
  },
  methods: {
    initGoogle() {
      const SCOPES = [
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/user.gender.read',
      ].join(' ');

      const onLoadGoogleCallback = () => {
        this.tokenClient = google.accounts.oauth2.initTokenClient({
          client_id: process.env.VUE_APP_GOOGLE_OAUTH_APP_KEY,
          scope: SCOPES,
          prompt: '', // '' | 'none' | 'consent' | 'select_account'
          callback: this.onSignIn, // function to handle the response after login. 'access_token' will be returned as property on the response
        });
      };
      let script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = onLoadGoogleCallback;
      document.body.appendChild(script);
    },
    prompt() {
      if (this.disabled) {
        return;
      }
      this.tokenClient.requestAccessToken();
    },
  },
  name: 'google-sign-in-button',
};
</script>

<style lang="scss" scoped>
.google-sign-in-button.disabled {
  pointer-events: none;
  .abcRioButtonBlue {
    background-color: #f2f2f2;
  }
}
.abcRioButton {
  height: 50px;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.abcRioButtonBlue .abcRioButtonContentWrapper {
  border: 1px solid transparent;
}

.abcRioButtonBlue {
  /*background-color: #4285f4;*/
  background-color: #ffffff;
  border: none;
  color: #666;
  &:active {
  }
  &:hover {
  }

  .abcRioButtonIconImage {
    width: 18px;
    height: 18px;
  }

  .abcRioButtonIcon {
    background: white;
    padding: 15px;
    border-radius: 4px;
    float: left;
  }
  .abcRioButtonContents {
    letter-spacing: 0.21px;
    margin-left: 6px;
    margin-right: 6px;
    vertical-align: top;
    line-height: 48px;
  }

  svg {
    overflow: hidden;
  }
}
</style>
