<template>
  <div class="prospect-request-end-action">
    <div>{{ title }}</div>
    <div>{{ subtitle }}</div>
    <mcr-button-router-link-to-pr @click="onClick" :label="buttonLabel"></mcr-button-router-link-to-pr>
  </div>
</template>

<script>
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';

export default {
  props: {
    title: {type: String, default: 'Need help?'},
    subtitle: {type: String, default: 'Speak to one of our roots advisors'},
    buttonLabel: {type: String, default: "Let's talk!"},
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
  components: {McrButtonRouterLinkToPr},
  name: 'prospectRequestEndAction',
};
</script>

<style lang="scss" scoped>
.prospect-request-end-action {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: rgba(216, 208, 208, 0.4);
  padding: 30px;

  .mcr-button {
    margin-top: 25px;
    padding: 0 20px;
  }
}
</style>
