<template>
  <div class="profile-edit-item" :class="{disabled: submitting}">
    <span class="label">{{ label }}</span>

    <div class="edit-item-content">
      <div class="edit-data">
        <span v-show="!editing">{{ type === 'password' ? '******' : content }}</span>

        <input
          :type="type ? type : 'text'"
          :placeholder="placeholder"
          v-model="value"
          v-show="editing"
          @keyup.enter="triggerEditing"
          :ref="requestkey"
        />

        <a @click="triggerEditing" v-if="!hiddenEdit && !submitting">{{ editing ? 'Save' : 'Edit' }}</a>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'profile-edit-item',

  props: {
    content: String,
    label: String,
    placeholder: String,
    type: String,
    savedOnItem: Function,
    hiddenEdit: Boolean,
    requestkey: String,
    user: Object,
  },
  data() {
    return {
      editing: false,
      value: this.content,
      relateKey: this.requestkey,
      submitting: false,
    };
  },
  methods: {
    triggerEditing() {
      if (this.editing) {
        // to save
        this._startSave();
        return;
      }
      this.editing = true;
      this.$nextTick(() => {
        this.$refs[this.requestkey].focus();
      });
    },

    _startSave() {
      if (!this.relateKey || this.relateKey.length <= 0) {
        return;
      }

      if (!this.value || this.value.length <= 0) {
        return;
      }

      if (this.submitting) {
        return;
      }
      this.submitting = true;

      let params = {};

      params[this.relateKey] = this.value;

      this.network.member
        .changeData(params, undefined, this.user.resource_uri)
        .then(res => {
          this.$emit('savedOnItem');
        })
        .finally(() => {
          this.submitting = false;
          this.editing = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import './profile';

.profile-edit-item {
  .edit-item-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .edit-data {
      display: flex;
    }
  }
}
</style>
