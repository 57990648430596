<template>
  <div class="email-input">
    <input
      :value="email"
      placeholder="Email"
      :class="{'has-error': error}"
      type="email"
      ref="input"
      class="input"
      @input="onInput"
      @keyup.enter="$emit('enter')"
      @blur="onBlur"
    />
    <transition name="fade">
      <div class="input-error" v-if="error">
        {{ error }}
      </div>
    </transition>
  </div>
</template>

<script>
import {isEmailValid} from '@common/utils/utils';

export default {
  props: {
    email: String,
  },
  data() {
    return {
      error: '',
    };
  },
  methods: {
    onInput(event) {
      if (this.error && isEmailValid(event.target.value)) {
        this.error = '';
      }
      this.$emit('input', event);
    },
    onBlur() {
      this.error = this.email && !isEmailValid(this.email) ? 'Please enter your email in a supported format.' : '';
    },
  },
};
</script>

<style scoped lang="scss">
.email-input {
  input {
    width: 100%;
  }
  .input-error {
    margin-bottom: 5px;
  }
}
</style>
