<template>
  <div class="institution-sign-in-button" :class="{disabled: disabled}" @click="signIn">
    <school-outline-icon></school-outline-icon>

    <div class="text">
      <bounce-loading v-if="loading"></bounce-loading>
      <slot v-else></slot>
    </div>
  </div>
</template>

<script>
import BounceLoading from '@common/elements/loading-indicators/bounceLoading';
import SchoolOutlineIcon from 'vue-material-design-icons/SchoolOutline';

export default {
  props: {
    disabled: Boolean,
    loading: Boolean,
  },
  methods: {
    signIn() {
      this.$emit('sign-in');
    },
  },
  components: {SchoolOutlineIcon, BounceLoading},
};
</script>

<style lang="scss" scoped>
.institution-sign-in-button {
  background-color: $mcr-dark-grey;
  color: white;
  height: 50px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;

  .school-outline-icon {
    margin-left: 10px;
  }
  .text {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
  }
}
.institution-sign-in-button.disabled {
  background-color: grey;
  pointer-events: none;
}
</style>
