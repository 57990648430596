<template>
  <google-sign-in-button id="google-signin-button" :on-sign-in="onSignIn" :on-failure="onFailure">
    <span>Log in with Google</span>
  </google-sign-in-button>
</template>

<script>
import GoogleSignInButton from './GoogleSignInButton';

export default {
  props: {
    referralCode: {type: String},
  },
  components: {GoogleSignInButton},
  methods: {
    onSignIn(user) {
      const payload = {accessToken: user.access_token, referralCode: this.referralCode};
      this.$store
        .dispatch('googleSignInAction', payload)
        .then(response => {
          this.$emit('success', response);
        })
        .catch(error => {
          this.$emit('fail', error);
        });
    },
    onFailure(response) {
      this.$emit('fail', response);
    },
  },
  name: 'google-sign-in-button-container',
};
</script>

<style scoped></style>
