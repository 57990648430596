<template>
  <div class="reset-password-container">
    <div class="readable_content account-form-container">
      <h5>Reset Password</h5>
      <p class="subtitle">Please enter your new password</p>
      <div class="account-form">
        <input placeholder="New Password" v-model="password" type="password" @keyup.enter="_confirm()" />
        <input placeholder="Confirm Password" v-model="password_confirm" type="password" @keyup.enter="_confirm()" />

        <transition name="fade">
          <p class="error" v-if="errorMessage">
            {{ errorMessage }}
            <router-link v-if="showTryAgainButton" :to="{name: 'forgotpwd'}">request new link</router-link>
          </p>
        </transition>
        <transition name="fade">
          <p class="success" v-if="successMessage">
            {{ successMessage }}
          </p>
        </transition>
        <mcr-button @click="_confirm" :loading="submitting">
          <span>Confirm</span>
        </mcr-button>
      </div>
    </div>
  </div>
</template>

<script>
import mcrButton from '@common/elements/buttons/mcrButton';

export default {
  name: 'root-reset-password',
  components: {mcrButton},

  data() {
    return {
      token: '',
      password: '',
      password_confirm: '',
      errorMessage: '',
      showTryAgainButton: false,
      successMessage: '',
      submitting: false,
    };
  },
  mounted() {
    this.token = this.$route.query.token;

    if (!this.token || this.token.length <= 0) {
      return;
    }
  },
  methods: {
    _confirm() {
      // check on token and passwords
      if (!this.password || this.password.length <= 0 || this.password.length < 8) {
        this.errorMessage = 'Please ensure your password is longer than 8 characters.';

        return;
      }

      if (this.password !== this.password_confirm) {
        this.errorMessage = 'Passwords do not match.';
        return;
      }
      if (this.submitting) {
        return;
      }

      this.errorMessage = '';

      this.submitting = true;

      this.network.member
        .resetPassword({
          token: this.token,
          password: this.password,
          password_confirm: this.password_confirm,
        })
        .then(res => {
          this.errorMessage = '';
          if (!res || (res._reason && res._reason === 'Bad request')) {
            this.errorMessage = 'Reset link expired, please ';
            this.showTryAgainButton = true;
            return;
          }
          if (res._reason) {
            this.errorMessage = res._reason;
            this.showTryAgainButton = false;
            return;
          }

          this.$store.commit('setLoginPageTextState', 'Password reset successful! Please login...');
          this.$router.push({name: 'login'});
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import './login';
</style>
