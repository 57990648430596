<template>
  <facebook-sign-in-button :disabled="isConnected" @sign-in="facebookSignIn">
    <span v-if="isConnected">Facebook account connected</span>
    <span v-else>Connect Facebook Account</span>
  </facebook-sign-in-button>
</template>

<script>
import {mapGetters} from 'vuex';

import FacebookSignInButton from '@/base/elements/social-buttons/FacebookSignInButton';

export default {
  methods: {
    facebookSignIn(r) {
      this.$store.dispatch('facebookConnectAction', r.authResponse.accessToken).then(response => {
        this.$emit('success', response);
      });
    },
  },
  computed: {
    ...mapGetters(['socialAccountsState']),
    isConnected() {
      return Boolean(
        this.socialAccountsState && this.socialAccountsState.filter(a => a.provider.includes('facebook')).length
      );
    },
  },
  components: {FacebookSignInButton},
};
</script>

<style lang="scss" scoped>
.facebook-sign-in-button-container {
  background-color: #4267b2;
  color: white;
  height: 50px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 10px;

  .text {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
  }
}
</style>
