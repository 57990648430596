<template>
  <div class="facebook-sign-in-button" :class="{disabled: disabled}" @click="facebookSignIn">
    <facebook-icon></facebook-icon>
    <div class="text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import FacebookIcon from 'vue-material-design-icons/Facebook';

export default {
  props: {
    disabled: Boolean,
  },
  created() {
    if (!this.disabled) {
      this.initFacebook();
    }
  },
  methods: {
    initFacebook() {
      window.fbAsyncInit = () => {
        FB.init({
          appId: process.env.VUE_APP_FACEBOOK_OAUTH_APP_KEY,
          cookie: true,
          xfbml: true,
          version: process.env.VUE_APP_FACEBOOK_OAUTH_VERSION,
        });
        FB.AppEvents.logPageView();
      };

      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    },
    facebookSignIn() {
      FB.login(
        r => {
          if (r.status !== 'connected') {
            return;
          }
          this.$emit('sign-in', r);
        },
        {scope: 'email'}
      );
    },
  },
  components: {FacebookIcon},
};
</script>

<style lang="scss" scoped>
.facebook-sign-in-button {
  background-color: #4267b2;
  color: white;
  height: 50px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;

  .facebook-icon {
    margin-left: 10px;
  }
  .text {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
  }
}
.facebook-sign-in-button.disabled {
  background-color: grey;
  pointer-events: none;
}
</style>
