<template>
  <div class="profile-edit-password" :class="{disabled: submitting}">
    <div
      class="profile-edit-item profile-edit-password-item old-password"
      v-show="editing && user && user.is_password_usable"
    >
      <span class="label">Password</span>
      <input
        type="password"
        placeholder="Enter old password"
        v-model="password_old"
        v-show="editing"
        @keyup.enter="triggerEditing"
        ref="password_old"
      />
    </div>

    <div class="profile-edit-item password">
      <span class="label">{{ editing && user && user.is_password_usable ? '' : 'Password' }}</span>
      <span class="profile-edit-content" v-show="!editing">{{ passwordValue }}</span>
      <input
        type="password"
        placeholder="Enter new password"
        v-model="password"
        v-show="editing"
        @keyup.enter="triggerEditing"
        ref="password"
      />
      <a @click="triggerEditing" v-if="!editing && !submitting">{{ editing ? 'Save' : 'Edit' }}</a>
    </div>

    <div class="profile-edit-item profile-edit-password-item password-confirm" v-if="editing">
      <span class="label"></span>
      <input
        type="password"
        placeholder="Confirm new password"
        v-model="password_confirm"
        v-show="editing"
        @keyup.enter="triggerEditing"
      />
      <a @click="triggerEditing" v-if="!submitting">{{ editing ? 'Save' : 'Edit' }}</a>
    </div>

    <transition name="fade">
      <p class="error" v-if="errorMessage && errorMessage.length > 0">
        {{ errorMessage }}
      </p>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'profile-edit-password',
  props: ['user', 'savedOnItem'],

  data() {
    return {
      password_old: '',
      password_confirm: '',
      password: '',
      submitting: false,
      errorMessage: '',
      editing: false,
    };
  },
  computed: {
    passwordValue() {
      return this.user.is_password_usable ? '******' : 'Set a password';
    },
  },
  methods: {
    triggerEditing() {
      if (this.editing) {
        if (this.user && this.user.is_password_usable && (!this.password_old || this.password_old.length <= 0)) {
          this.errorMessage = 'Please enter your old password';
          return;
        }

        if (!this.password || this.password.length <= 0 || this.password.length < 8) {
          this.errorMessage = 'Please ensure your password is longer than 8 characters.';

          return;
        }

        if (this.password !== this.password_confirm) {
          this.errorMessage = 'Passwords do not match.';
          return;
        }
        if (this.submitting) {
          return;
        }

        this.errorMessage = '';

        this.submitting = true;

        this.network.member
          .changePassword({
            password: this.password,
            password_confirm: this.password_confirm,
            old_password: this.password_old === '' ? undefined : this.password_old,
          })
          .then(res => {
            this.$emit('savedOnItem');
          })
          .finally(() => {
            this.submitting = false;
            this.editing = false;
          });

        return;
      }
      this.editing = true;

      this.$nextTick(() => {
        this.$refs.password_old.focus();
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import './profile';

.error {
  padding-left: 110px;
  border-bottom: 1px solid $divider-line-color;
  padding-bottom: 14px;
}

.profile-edit-password-item:not(:first-of-type) {
  border-top: 0;
  padding-top: 0;
}
</style>
