<template>
  <div class="verify-account-page">
    <div class="readable_content" v-if="loading">
      <h4>Verifying...</h4>
    </div>

    <div class="readable_content" v-else>
      <h4>{{ title }}</h4>

      <mcr-button-router-link v-if="!userIsLoggedInState && !error" :to="{name: 'login'}" class="login-btn"
        >Log In</mcr-button-router-link
      >
      <mcr-button-router-link v-if="!userIsLoggedInState && error" :to="loginToResendRoute" class="login-btn"
        >Log In to Resend Verification</mcr-button-router-link
      >

      <div v-if="error && userIsLoggedInState">
        <a @click="clickResend">Resend verification</a>
      </div>
      <div v-if="!error">
        <router-link :to="{name: 'main-page'}">Back to Home</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      loading: true,
      error: false,
    };
  },
  mounted() {
    if (this.$route.query.resend) {
      return this.clickResend();
    }
    let tokenString = this.$route.query.token;
    if (!tokenString) {
      this.loading = false;
      this.error = true;
      return;
    }
    this.network.verify
      .verifyEmail({token: tokenString})
      .then(res => {
        this.$store.commit('setResponseUserState', res);
        this.$store.commit('setUserKeyState', res.key);
      })
      .catch(err => {
        this.error = true;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters(['userIsLoggedInState']),
    title() {
      return this.error
        ? 'Sorry, your verification link has expired.'
        : 'Thanks! Your email address has been verified.';
    },
    loginToResendRoute() {
      const route = {name: 'verify-email', query: {token: this.$route.query.token, resend: true}};
      const redirect = this.$router.resolve(route).href;
      return {name: 'login', query: {redirect}};
    },
  },
  methods: {
    clickResend() {
      if (this.userIsLoggedInState) {
        return this.resendVerifyEmail();
      }
    },
    resendVerifyEmail() {
      this.network.member
        .resendVerifyEmail()
        .then(() => {
          this.$router.push({name: 'require-verify', query: {resent: true}});
        })
        .catch(error => {
          const message = error && error.response && error.response.data && error.response.data.error;
          this.$toasted.error(message || 'Error');
        });
    },
  },
  components: {McrButtonRouterLink},
};
</script>

<style lang="scss" scoped>
.verify-account-page {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 40px;

  h4 {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 30px;
  }

  .login-btn {
    margin-bottom: 20px;
  }
}
</style>
